<template>
    <div id="con">
       <p style="font-size: 40px;font-weight: bold">Wireless Connectivity</p>
        <p style="line-height: 1.5; ">As the wireless connectivity  communication industry, the industry emphasizes on the developments of new key features, such as Wi-Fi Alliance connectivity  and low latency communications.</p>
        <p style="line-height: 1.5; ">To meet these feature upgrades and growing usage demands, the industry requires innovations in wireless connectivity technologies, more efficient, high-performance and low-power semiconductor devices are crucial for supporting the continuous evolutions in wireless connectivity  communication technologies.</p>
        <p style="line-height: 1.5; ">HFC has various competitive semiconductor manufacturing capabilities 55nm and 28nm technology nodes, and will offer logic, radio frequency (RF) and embedded memory technologies, which enable customers’ integrated circuit (IC) designs on various IC products, such as Wi-Fi, Bluetooth, NFC, MCU and etc.  HFC will continue to widen its technology portfolios to ensure customers’ success in providing IC products for various end applications.</p>
    </div>
</template>

<script>
    export default {
        name: "MarketSub2"
    }
</script>

<style scoped>
    *{
        margin:0;
        padding:0;
    }
    #con{
        font-family: open sans,sans-serif;
        font-size: 20px;
        color: white;
        width: 1000px;
    }
#con p{
    font-size: 20px;
    font-family: open sans,sans-serif;
    color: white;
}
</style>
