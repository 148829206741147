<template>
<div>
    <div>
    <el-carousel :interval="3000" arrow="always" height="450px">
        <el-carousel-item v-for="(item, index) in urls" :key="index">
            <img v-bind:src="item.url" style="width: 100%;">
        </el-carousel-item>
    </el-carousel>
        </div>
    <div id="content">
       <p style="font-size: 30px;font-weight: bold">About HFC</p>
        <p style="line-height: 1.5; ">Hangzhou HFC Semiconductor (杭州积海半导体有限公司) was established on September 6th,2019 and is headquartered in Qiantang District, Hangzhou, China.  HFC positions itself as a semiconductor service provider in China and offers manufacturing process technologies, testing services and IP services.</p>
        <p style="line-height: 1.5; ">In November 2021, HFC had the initial groundbreaking at its Hangzhou site. Within its 181-acres land parcel in Hangzhou, China, HFC plans to build a 12-inch wafer fab with two phases, a total of planned capacity over 80 kWPM (thousand wafer per month) when fully constructed.
        </p>
       <p style="line-height: 1.5; "> HFC plans to offer and support customers with 55nm (nanometer) and 28nm (nanometer) semiconductor process technologies and cover a variety of end market applications including consumer electronics, wireless connectivity and Internet of Things (IoT).
       </p>
    </div>
</div>
</template>

<script>
    export default {
        name: "AboutHfc",
        data(){
            return{
                urls:[
                    {url:require('../assets/baner1.jpg')},
                    {url:require('../assets/baner2.jpg')},
                ]
            }
        }
    }
</script>

<style scoped>
    *{padding: 0;margin:0}
    .el-carousel__item h3 {
        color: #475669;
        font-size: 18px;
        opacity: 0.75;
        line-height: 300px;
        margin: 0;
    }

    .el-carousel__item:nth-child(2n) {
        background-color: #99a9bf;
    }

    .el-carousel__item:nth-child(2n+1) {
        background-color: #d3dce6;
    }

    #content{
        height: 450px;
        background-color: rgb(5,77,110);
    }
    #content p{
        margin-left: 360px;
        font-size: 20px;
        font-family: open sans,sans-serif;
        color: white;
        padding: 15px 0px;
        width: 1000px;
    }
</style>
