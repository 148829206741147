<template>
    <div id="content">
        <p style="font-size: 40px;font-weight: bold">Technologies</p>
        <p style="line-height: 1.5; ">HFC are developing various competitive semiconductor manufacturing platform 55nm and 28nm including logic and specialty technologies. The major target market, there are  Consumer Electronics market, Wireless Connectivity market and Internet of Things(IoT) market. HFC initially will start and offer logic and mixed-signal/radio frequency (RF) technology platform, following to develop specialty technologies BCD and embedded memory and low power platform which enable customers’ integrated circuit (IC) designs on various market and IC products application.  HFC will continue to widen its technology portfolios, collaborates with 3rd party partners services to ensure customers’ success in providing consumer IC market and production.</p>
        <p style="font-size: 30px;font-weight: bold">55nm</p>
        <p style="line-height: 1.5; ">HFC's 55nm low power process technology is a 90% shrink from 65nm layout drawing and provides a better of PPA performance for customer design. HFC’s 55nm platform technology is shallow trench isolation with dual-gate oxide proves, 1.2v/2.5v supply voltage and support 2.5v under drive 1.8v and overdrive 3.3v for different product design applications. Self-aligned Ni-silicide, used Low-κ for inter-metal dielectric and dual-damascene Cu interconnection, totally 7 Cu metal with  AL Redistribution (ALRDL) option offered 28K or 14.5K. When logic process ready, we will spread 55nm technology platform to specialty technologies, including mixed signal and RF, HV, BCD, embedded flash memory (eFlash) platform to support various production application.</p>
        <img src="../assets/t1.jpg"  style="margin-left: 400px">
        <p style="font-size: 30px;font-weight: bold">28nm</p>
        <p style="line-height: 1.5; ">28nm process technologies shall support IC products with good trade-off between performances and costs, such as consumer electronics set-top boxes, digital-TVs, wired/wireless connectivity products, high-end consumer products, embedded memory products etc. HFC plans to develop 28nm technologies featured with High-k metal gate (HKMG) with matched N/PMOS driving current ratio. HFC’s 28nm HKMG technology platform offers five devices, ULVt, LVt, SVt, HVt and UHVt, also supports two IO device options, 1.8v/2.5v. Considering layout dependence effect in 28nm technology, HFC’s 28nm model offers various layout dependence effect such as LOD, WPE, OSP and PSE to accurate geometry effects on devices.</p>
        <img src="../assets/t2.jpg" style="margin-left: 600px;margin-bottom: 50px">
    </div>
</template>

<script>
    export default {
        name: "Tech"
    }
</script>

<style scoped>
    *{padding: 0;margin:0}
    #content{
        background-color: rgb(5,77,110);
    }
    #content p{
        margin-left: 360px;
        font-size: 20px;
        font-family: open sans,sans-serif;
        color: white;
        padding: 15px 0px;

    }
</style>
