<template>
    <div id="con">
       <p style="font-size: 40px;font-weight: bold">Consumer Electronics</p>
        <p style="line-height: 1.5; ">Consumer Electronics becomes an important part of human’s everyday lives.  Consumer Electronics not only enriches people’s daily lives with various digital audio and video contents, but also brings comforts and conveniences to people.  Ranging from digital televisions (DTV), set-up box (STB), media box, digital video (DV), panels display and various home appliances. Semiconductors IC devices play important roles in enabling continuous innovations in the consumer electronics market.</p>
        <p style="line-height: 1.5; ">Different types of consumer electronic applications have different emphasis on semiconductor usages.  For instance, for black appliances (e.g. DTV, STB, DV), ISP, Audio IC, Display IC, PMIC, CIS, Wireless/Wired IC, Controllers and peripheral ICs are generally required.</p>
        <p style="line-height: 1.5; ">HFC has semiconductor manufacturing technologies 55nm and 28nm technology nodes that can support IC design customers’ needs in the consumer electronics market. In particular, HFC will offer consumer grade solutions in Logic, RF and Display IC technologies.  HFC will continue to explore the consumer electronics market with customers and develop more technologies to support customers’ future demands.</p>
    </div>
</template>

<script>
    export default {
        name: "MarketSub1"
    }
</script>

<style scoped>
    *{
        margin:0;
        padding:0;
    }
    #con{
        font-family: open sans,sans-serif;
        font-size: 20px;
        color: white;
        width: 1000px;
    }
#con p{
    font-size: 20px;
    font-family: open sans,sans-serif;
    color: white;
}
</style>
