<template>
    <div id="content">
        <div id="content_tab">
          <ul>
              <li class="act"><a href="javascript:void(0);"  @click="selectSubMenu(1)">Consumer Electronics</a></li>
              <li ><a href="javascript:void(0);"  @click="selectSubMenu(2)">Wireless Connectivity</a></li>
              <li ><a href="javascript:void(0);"  @click="selectSubMenu(3)">Internet of Things (IoT)</a></li>
          </ul>

            <component :is="compName"></component>
        </div>
    </div>
</template>

<script>
    import MarketSub1 from "./MarketSub1";
    import MarketSub2 from "./MarketSub2";
    import MarketSub3 from "./MarketSub3";
    export default {
        components:{
            MarketSub1,
            MarketSub2,
            MarketSub3,
        },
        name: "Market",
        data(){
            return{
                compName:"MarketSub1",
                activeName:"active"
            }

        },
        methods:{
            selectSubMenu(index){
                if(index==1){
                    this.compName="MarketSub1";
                }else if(index==2){
                    this.compName="MarketSub2";
                }else if(index==3){
                    this.compName="MarketSub3";
                }

            }
        }
    }
</script>

<style scoped>
    *{padding: 0;margin:0}
    #content{
        background-color: rgb(5,77,110);
        height:600px;
    }

    #content_tab{
        margin-left: 360px;

    }
    #content_tab ul{
        list-style-type: none;
        width: 1200px;

    }
    #content_tab ul li{
        background-color: darkgray;
        float: left;
    }
    #content_tab a{
        width:300px;
        height: 60px;
        display: inline-block;
        text-decoration: none;
        cursor:pointer;
        line-height: 60px;
        text-align: center;
        font-family: open sans,sans-serif;
        font-size: 20px;

    }
    #content_tab .act a {
        background-color: rgb(0,87,166);
        color: white;
    }
    #content_tab a{
        color: white;
    }

</style>
