<template>
    <div id="con">
       <p style="font-size: 40px;font-weight: bold"> Internet of Things (IoT)</p>
        <p style="line-height: 1.5; ">Internet of Things (IoT) envisions the world is fully connected with various and fragmented application devices.  Each IoT device is integrated with data and decision processing, low power, always-on connectivity and sensing capabilities, which enable remote monitoring, status, manipulation and evaluation of trends of such device.  IoT applications range from personal wearables, smart home, smart appliances, smart manufacturing to smart cities and etc.</p>
        <p style="line-height: 1.5; ">Majority of the IoT applications and devices often require low-power and low-leakage, always-on features, sensing, and low bit rate data transmission functions.  Frequently, IoT devices are also constrained by limited form factor and sizes, limited battery capacities, and low cost requirements.  As a result, semiconductor IC design customers need to offer the best performance-power-area-cost balanced IC products to be competitive in the IoT market.</p>
        <p style="line-height: 1.5; ">GHS plan has various semiconductor manufacturing technologies such as logic, RF, BCD and eNVM which are the essential building blocks for customer to designing  IoT (Internet of Things) related IC products. GHS will continue to develop 55nm and 28nm technology nodes to support customers’ needs in the IoT market domain.</p>
    </div>
</template>

<script>
    export default {
        name: "MarketSub3"
    }
</script>

<style scoped>
    *{
        margin:0;
        padding:0;
    }
    #con{
        font-family: open sans,sans-serif;
        font-size: 20px;
        color: white;
        width: 1000px;
    }
#con p{
    font-size: 20px;
    font-family: open sans,sans-serif;
    color: white;
}
</style>
