<template>
  <div>
    <div id="header">
      <div id="img">
        <img src="./assets/hfc.png">
      </div>
      <div id="nav">
        <ul>
          <li><a href="javascript:void(0);"  @click="selectMenu(1)">About HFC</a></li>
          <li><a href="javascript:void(0);"  @click="selectMenu(2)">Technologies</a></li>
          <li><a href="javascript:void(0);"  @click="selectMenu(3)">Market</a></li>
          <li><a href="javascript:void(0);"  @click="selectMenu(4)">Career</a></li>
          <li><a href="javascript:void(0);"  @click="selectMenu(5)">Contact Us</a></li>
          <li><a href="https://wcrs.ghsmc.com ">投诉举报入口</a></li>
        </ul>
      </div>
    </div>
    <component :is="compName"></component>
    <div id="footer">
      <div id="h_t"></div>
      <div id="footer_about">
        <p style="font-size: 20px ;font-weight: bold;padding: 10px 0px;">ABOUT</p>
        <p>Information: info@ghsmc.com</p>
        <p>Career Contact: recruitment@ghsmc.com</p>
        <p style="font-size: 20px; font-weight: bold;padding: 10px 0px;">Hangzhou</p>
        <p>Office:   No 811 Dongken Road,  Qiantang District CHI-310005, Hangzhou/Zhejiang China</p>
        <p>Tel:         +86 571-28289888-810110</p>
      </div>
      <div id="footer_info">
        <p style="font-family:helvetica-w01-roman,helvetica-w02-roman,helvetica-lt-w10-roman,sans-serif; color: #cccccc">© 2024 by Hangzhou HFC Semiconductor Corp. <a  style="text-decoration: none;text-underline: none;font-family:helvetica-w01-roman,helvetica-w02-roman,helvetica-lt-w10-roman,sans-serif; color: #cccccc" href="https://beian.miit.gov.cn/#/Integrated/recordQuery" target="_blank">ICP备案/许可证号:浙ICP备2023035694号-1</a></p>
      </div>
    </div>
  </div>
</template>

<script>

import AboutHfc from "./components/AboutHfc";
import Tech from "./components/Tech";
import Market from "./components/Market";
import Career from "./components/Career";
export default {
  name: 'App',
  data(){
    return{
      compName:'AboutHfc'
    }
  },
  components: {
    AboutHfc,Tech,Market,Career
  },
  methods:{

    selectMenu(index){
      if(index==1){
        this.compName='AboutHfc';
      }else if(index==2){
        this.compName='Tech';
      }else if(index==3){
        this.compName='Market';
      }else if(index==4){
        this.compName='Career';
      }else if(index==5){
        this.compName='AboutHfc';
      }else{
        this.compName='AboutHfc';
      }
    }
  }


}
</script>

<style scoped>
*{
  padding: 0;
  margin: 0;
}
.ipclink {
  text-underline: none;
}

  #header{
    height: 75px;
    background-color: rgb(5,77,110);
    border-bottom: 1px solid rgb(4,60,86);
  }
  #img{
    margin-left:50px;
  }
   #img img{
     height: 71px;
     width: 190px;
     float: left;

   }
  #nav {
    height:75px;
    float: left;
    margin-left: 90px;
  }
  #nav ul{
    height: 75px;
  }
  #nav ul li{

    border-bottom-color: rgb(5,77,110);
    background-color: rgb(5,77,110);
    list-style-type: none;
    float:left;
    padding-left:5px;
  }
  #nav a{
    width: 140px;
    height: 75px;
    text-decoration: none;
    cursor:pointer;
    color: #fff;
    font-weight: 400;
    font-style: normal;
    color: #fff;
    font-size: 20px;
    font-family: "open sans,sans-serif";
    line-height: 75px;
    text-align: center;
   display: block;
  }
  #h_t{
    margin: 20px 0px 20px 360px;
    border-bottom: rgb(96,94,94) solid 5px;
    width: 600px;
  }
  #footer_about{
    margin-left: 360px;
   font-family:  "open sans,sans-serif";

  }

  #footer_info{
    background-color: #2f2e2e;
    text-align: center;
    height: 60px;
    line-height: 60px;
  }
</style>
