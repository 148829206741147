<template>
    <div id="career">
        <div id="con">
            <p>校園招聘正在進行中...</p>

        </div>
    </div>
</template>

<script>
    export default {
        name: "Career"
    }
</script>

<style scoped>
    *{padding: 0;margin:0}
    #career{
        background: url("../assets/carrer.jpg") no-repeat;
        height:500px;
    }
#con{
    margin-left: 360px;
    font-size: 20px;
    color: white;
    font-family: open sans,sans-serif;
}
</style>
